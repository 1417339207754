@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap");

/* $Id: $ */

.clientInput {
  outline: none;
  margin: 0 auto;
  padding: 5px 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: 1px solid grey;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}
.clientInput:focus {
  outline: 2px #ae3a3a;
}
.combo {
  margin-left: 0%;
}
.logo {
  width: 20%;
  height: 50%;
}

#clientUl {
  list-style: none outside none;
  padding-left: 0rem;
  font-family: "Manrope"; 
}
.formClient {
  margin-top: 1rem;
  margin-left: 20%;
  width: 60%;
  background-color: #dcdde1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*****************************Checkbox styling********************************************/

/* Styling Checkbox Starts */
.checkbox-label {
  color: white;
  display: block;
  position: relative;
  margin-top: 0%;
  margin-left: 15%;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 20px;
  width: 24px;
  clear: both;
}
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .mark {
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: whitesmoke;

  border-radius: 2px;
  transition: all 0.3s ease-in;
  border: 2px solid gray;
  box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 rgb(0 0 0 / 19%);
}

.checkbox-label .newCheckMark {
  top: -26px;
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: whitesmoke;

  border-radius: 2px;
  transition: all 0.3s ease-in;
  border: 2px solid gray;
  box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 rgb(0 0 0 / 19%);
}

.checkbox-label input:checked ~ .mark {
  background-color: #1988d6;
  border-radius: 5px;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid rgb(0, 102, 255);
}
.checkbox-label .mark::after {
  position: absolute;
  content: "";
  border-radius: 5px;
}
.checkbox-label input:checked ~ .mark::after {
  transform: rotate(45deg) scale(1.2);
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  border-radius: 0;
}
/* For Ripple Effect */
.checkbox-label .mark::before {
  position: absolute;
  content: "";
  border-radius: 10px;
  border: 5px solid yellow;
  transform: scale(0);
}

.checkbox-label input:checked ~ .mark::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  transform: scale(3);
  opacity: 0;
  transition: all 0.3s ease-out;
}
/***************new Check checkbox style**********************************/
.checkbox-label .newCheckMark {
  top: -26px;
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: whitesmoke;

  border-radius: 2px;
  transition: all 0.3s ease-in;
  border: 2px solid gray;
  box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 rgb(0 0 0 / 19%);
}

.checkbox-label input:checked ~ .newCheckMark {
  background-color: #1988d6;
  border-radius: 5px;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid rgb(0, 102, 255);
}
.checkbox-label .newCheckMark::after {
  position: absolute;
  content: "";
  border-radius: 5px;
}
.checkbox-label input:checked ~ .newCheckMark::after {
  transform: rotate(45deg) scale(1.2);
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  border-radius: 0;
}
/* For Ripple Effect */
.checkbox-label .newCheckMark::before {
  position: absolute;
  content: "";
  border-radius: 10px;
  border: 5px solid yellow;
  transform: scale(0);
}

.checkbox-label input:checked ~ .newCheckMark::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  transform: scale(3);
  opacity: 0;
  transition: all 0.3s ease-out;
}

/***********************************************************************/
@media (min-width: 2560px) {
  #submitbtnCf {
    margin-left: 46%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  #submitbtnCf {
    margin-left: 46%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  #submitbtnCf {
    margin-left: 44%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #submitbtnCf {
    margin-left: 42%;
  }
}
/***********************************************************************/

.loadingAnimation {
  display: flex;
  align-items: center;
  padding-left: 50%;
}

.comboInput {
  width: 192px !important;
  height: 28px;
  outline: none;
  padding: 5px 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: 1px solid grey;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.buttonCf {
  all: unset;
  /* padding:5px 10px; */
  padding: 5px 12px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: #f88379;
}
.submitBtnCf {
  /* border:2px solid black; */
  /* padding:3px 10px; */
  color: black;
  transition: all 0.3s;
}

/* .submitBtn:hover {
      background-color:#038f03;
      color:white;
  } */
.generateBtnCf {
  transition: all 0.3s;
  box-shadow: 0px 5px 5px #00000030;
  cursor: pointer;
}

.generateBtnCf:hover,
.submitBtnCf:hover {
  background-color: #b3605a;
  box-shadow: 0px 3px 5px #00000030;
}

/***********************************************************************/
@media (min-width: 2560px) {
  #searchBtnCf {
    font-size: 14px;
    padding: 3px 9px;
    margin-left: 5px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  #searchBtnCf {
    font-size: 14px;
    padding: 3px 9px;
    margin-left: 5px;
  }
}

@media (min-width: 1137px) and (max-width: 1439px) {
  #searchBtnCf {
    font-size: 14px;
    padding: 3px 9px;
    margin-left: 5px;
  }
}

@media (min-width: 1024px) and (max-width: 1136px) {
  #searchBtnCf {
    font-size: 14px;
    padding: 3px 9px;
    margin-left: -100px;
    margin-top: 38px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #searchBtnCf {
    font-size: 14px;
    padding: 3px 9px;
    margin-left: 90px;
    margin-top: 10px;
  }
}
/***********************************************************************/

.zf-backgroundBg {
  background: #f5f5f5;
}

.zf-tempContDiv .zf-tempContDiv .zf-tempContDiv .zf-pdfTextArea {
  background: #fff;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 5px;
  font-size: 13px;
}

.zf-overflow {
  overflow: hidden;
}

/*****************template Header Starts Here****************/

.zf-tempHeadContBdr {
  display: flex;
  flex-direction: column;
  background: #00A6E8;
  border-bottom: 1px solid #eaeaea;
  margin: 0;
  padding: 10px 25px;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.zf-tempHeadContBdr .zf-frmTitle {
  text-align: left;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-size: 2em;
}
.zf-tempHeadContBdr .zf-frmDesc {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  font-family: "Manrope";
  font-weight: bold;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

/****************template Header Ends Here****************/

/***********************************************************************/
@media (min-width: 2560px) {
  .comboxbox {
    display: flex;
    padding-left: 50px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .comboxbox {
    display: flex;
    padding-left: 44px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .comboxbox {
    display: flex;
    padding-left: 44px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .comboxbox {
    padding-left: 44px;
  }
}
/***********************************************************************/

.zf-subContWrap {
  padding: 0;
  margin: 10px;
  margin-bottom: 0;
}

.zf-tempFrmWrapper {
  padding: 10px 15px;
  margin: 1px 0;
  position: relative;
  display: flex;
  flex-direction: row;
}

.zf-tempFrmWrapper .zf-tempContDiv {
  margin: 0;
  padding: 0;
}

.zf-tempFrmWrapper .zf-labelName {
  font-weight: bold;
  font-size: 14px;
  color: #444;
}

.zf-form-sBox {
  padding: 4px;
  border: 1px solid #e5e5e5;
  font-size: 13px;
}

.zf-name .zf-tempContDiv span,
.zf-phone .zf-tempContDiv span,
.zf-time .zf-tempContDiv span {
  float: left;
  display: block;
}

.zf-name .zf-tempContDiv span {
  margin-bottom: 5px;
  margin-left: 4%;
}
.zf-name .zf-tempContDiv span.last {
  margin-right: 0;
}
.zf-name .zf-tempContDiv span label {
  display: block;
  padding-top: 3px;
}
.zf-name .zf-tempContDiv input[type="text"] {
  width: 100%;
}

.zf-currency .zf-tempContDiv span {
  display: inline-block;
}
.zf-currency .zf-tempContDiv span label {
  display: block;
  padding-top: 3px;
}
.zf-currency .zf-tempContDiv .zf-symbol {
  font-size: 14px;
  margin-left: 5px;
  margin-top: 4px;
  width: auto;
  font-weight: bold;
}

.zf-decesion .zf-tempContDiv {
  width: 100% !important;
  margin-top: 4px;
}
.zf-decesion input[type="checkbox"] {
  display: block;
  height: 13px;
  margin: 0;
  padding: 0;
  width: 13px;
  float: left;
  margin-top: 4px;
}
.zf-decesion label {
  display: block;
  line-height: 21px;
  margin: 0px 0 0 25px !important;
  padding-bottom: 0 !important;
  width: 95% !important;
  float: none !important;
  line-height: 21px !important;
  text-align: left !important;
}

.zf-tempContDiv input[type="file"] {
  outline: none;
  border: 1px solid #ccc;
  margin: 0 auto;
  padding: 5px;
  width: auto;
}

.zf-section h2 {
  border-bottom: 1px solid #d7d7d7;
  font-size: 22px;
  color: #000;
  font-weight: 500;
  font-family: "Manrope";
  padding-bottom: 10px;
}
.zf-section p {
  color: #847f7f;
  margin-top: 10px;
}

.zf-note .zf-labelName {
  font-size: large;
  padding-top: 7px;
}
.zf-templateWrapper .zf-note {
  overflow: hidden;
}

.zf-date .zf-tempContDiv span label {
  display: block;
  padding-top: 3px;
  text-align: left;
}

.zf-time .zf-tempContDiv span label {
  display: block;
  padding-top: 3px;
  text-align: center;
}

.zf-time .zf-tempContDiv .zf-form-sBox {
  min-width: 58px;
}

.zf-time .zf-tempContDiv .zf-symbols {
  padding-top: 5px;
}

.zf-fmFooter {
  margin: 0;
  padding: 25px;
  text-align: center;
}

.zf-submitColor {
  color: #fff;
  border: 1px solid;
  border-color: #2f9eec #1988d6 #1988d6;
  background: #2596e6;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2f9eec),
    to(#1988d6)
  );
  background: -moz-linear-gradient(top, #2f9eec, #1988d6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f9eec', endColorstr='#1988d6');
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  margin-left: 50%;
}

.zf-submitColor:hover {
  background: #1988d6;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1988d6),
    to(#1988d6)
  );
  background: -moz-linear-gradient(top, #1988d6, #1988d6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1988d6', endColorstr='#1988d6');
}

/****************Field Small/Medium/Large Starts Here****************/

/***********************************************************************/

@media (min-width: 2560px) {
  .zf-medium .zf-tempContDiv input[type="text"],
  .zf-medium .zf-tempContDiv textarea,
  .zf-medium .zf-mSelect select,
  .zf-medium .zf-tempContDiv .zf-sliderCont,
  .zf-medium .zf-tempContDiv .zf-pdfTextArea {
    width: 120%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .zf-medium .zf-tempContDiv input[type="text"],
  .zf-medium .zf-tempContDiv textarea,
  .zf-medium .zf-mSelect select,
  .zf-medium .zf-tempContDiv .zf-sliderCont,
  .zf-medium .zf-tempContDiv .zf-pdfTextArea {
    width: 120%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .zf-medium .zf-tempContDiv input[type="text"],
  .zf-medium .zf-tempContDiv textarea,
  .zf-medium .zf-mSelect select,
  .zf-medium .zf-tempContDiv .zf-sliderCont,
  .zf-medium .zf-tempContDiv .zf-pdfTextArea {
    width: 120%;
  }
}

/* @media (min-width: 768px) and (max-width: 1023px) {
	.zf-medium .zf-tempContDiv input[type="text"], .zf-medium .zf-tempContDiv textarea, .zf-medium .zf-mSelect select, .zf-medium .zf-tempContDiv .zf-sliderCont, .zf-medium .zf-tempContDiv .zf-pdfTextArea{
		width:75%;
	}
} */
/***********************************************************************/

.zf-large .zf-tempContDiv input[type="text"],
.zf-large .zf-tempContDiv textarea,
.zf-large .zf-mSelect select,
.zf-large .zf-tempContDiv .zf-sliderCont,
.zf-large .zf-tempContDiv .zf-pdfTextArea {
  width: 100%;
}

/****************Field Small/Medium/Large Ends Here****************/

.zf-leftAlign {
  display: block;
}
.zf-leftAlign .zf-tempFrmWrapper .zf-labelName {
  float: left;
  width: 30%;
  line-height: 20px;
  margin-right: 15px;
}
.zf-leftAlign .zf-tempFrmWrapper .zf-tempContDiv {
  margin-left: 5%;
}

.zf-leftAlign .zf-slider .zf-tempContDiv {
  margin-top: 6px;
}

.zf-leftAlign .zf-decesion .zf-tempContDiv,
.zf-rightAlign .zf-decesion .zf-tempContDiv {
  margin-left: 0 !important;
}

.zf-rightAlign {
  display: block;
}
.zf-rightAlign .zf-tempFrmWrapper .zf-labelName {
  float: left;
  width: 30%;
  line-height: 20px;

  text-align: right;
  margin-right: 15px;
}
.zf-rightAlign .zf-tempFrmWrapper .zf-tempContDiv {
  margin-left: 35%;
}

/****************Form Top Align Starts Here****************/

.bottom-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

#searchtext {
  padding-top: 8px;
}

.clientNewInput {
  outline: none;
  height: 28px;
  margin-left: 1%;
  padding: 5px 10px;
  width: fit-content;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: 1px solid grey;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

#newInput {
  margin-bottom: 10px;

  width: 173%;
  height: 28px;
  margin-left: 31%;
}

.newFieldscls {
  display: flex;
  flex-direction: column;
  animation: renderNewCheck 1s;
}

.clientLabel {
  font-size: 120px;
  font-weight: bolder;
}
.checkFieldContainer {
  animation: renderLabel 1s;
  margin-top: 10px;
}
@keyframes renderLabel {
  0% {
    margin-top: 10%;
  }
  100% {
    margin-top: 10px;
  }
}
@keyframes renderNewCheck {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.checkEditButtons {
  margin-left: 20%;
}

#deleteCheck {
  margin-left: 10px;
}
#addCheck {
  margin-left: 10px;
}

.vlistDropdown {
  font-family: "Manrope";
  font-size: 14px;
}

/**************************************************************************************************/