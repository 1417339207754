@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;600;700;800&display=swap');

a {
    text-decoration: none;
}

.table-divider {
    margin: 0;
    border-top: 2px solid #000000;
}
.card-blue {
    font-size: 35px;
    color: #00A6E8;
    border-left: 5px solid #00A6E8;
    border-radius: 5px;
}

.card-purple {
    font-size: 35px;
    color: #7A459C;
    border-left: 5px solid #7A459C;
    border-radius: 5px;
}

.card-orange {
    font-size: 35px;
    color: #FAA817;
    border-left: 5px solid #FAA817;
    border-radius: 5px;
}

.card-green {
    font-size: 35px;
    color: #BAD630;
    border-left: 5px solid #BAD630;
    border-radius: 5px;
}

.card-black {
    border-left: 5px solid #5A5C69;
    border-radius: 5px;
}

.card-heading {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    font-family: "Manrope", sans-serif;
}

.card-description {
    font-size: 12px;
    font-weight: 400;
    color: #5A5C69;
    font-family: "Manrope", sans-serif;
    opacity: 0.8;
}

.button-number {
  font-size: 25px;
  font-weight: 800;
  color: #00A6E8;
  text-align: center;
  padding: 2px 10px;
  transition: width .40s ease-in-out;
}

.main {
  margin-left: 220px; /* Same as the width of the sidenav */
  padding: 0px 10px;
}