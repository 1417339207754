@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap");

.sidenav {
  height: 100%;
  width: 220px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #31373C;
  overflow-x: hidden;
  padding-top: 20px;
  font-family: "Manrope";
}

.sidenav-logo {
  /* margin-top: 0.5rem;
  margin-bottom: 0.5rem; */
  padding: 12px 0px 12px 0px;
  background: white;
  margin-bottom: 20px;
  margin-top: -20px;
}

/* .sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
} */

.sidenav .links {
  padding: 0px 5px;
}

.sidenav .links .link {
  margin: 8px 0px;
  height: 50px;
  color: #888;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
}

.icons{
  display: inline-block;
  width: 22px;
  height: 22px;
  color: white;
}

.sidenav .links .link span {
  display: inline-block;
  font-size: 13px;
  margin-left: 15px;
  color: white;
}

.sidenav .links .link:hover {
  background: #141516; 
  cursor: pointer;
}

.sidenav .divider {
  border-bottom: 1px solid #222;
  margin: 20px 0px;
  width: 100%;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

/******************************************************************************************/

.main {
  margin-left: 220px; /* Same as the width of the sidenav */
  font-size: 14px; 
  padding: 0px 10px;
}