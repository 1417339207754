* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.centered-text {
  text-align: center;
}
th {
  text-align: left;
  font-size: 13pt;
  font-family: Calibri, sans-serif;
  font-weight: bolder;
  word-wrap: break-word;
}
textarea {
  padding-left: 10px;
}
input {
  padding-left: 10px;
}
u {
  font-size: 14pt;
  font-family: Calibri, sans-serif;
}
td {
  text-align: left;
  padding-left: 5px;
  font-size: 13pt;
  font-family: Calibri, sans-serif;
  word-wrap: break-word;
}
/* .personalcellTitle{
  background-color: rgba(211, 211, 211, 0.5);
  
} */
h3 {
  font-size: 13pt;
  font-family: Calibri, sans-serif;
  font-weight: bolder;
}
input:focus {
  background-color: transparent;
}
.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 0px 0px;
  border-radius: 15px;
  font-size: 10px;
  text-align: center;
}
td:first-child,
th:first-child,
.personalinfo {
  background-color: rgba(211, 211, 211, 0.5);
  padding: 3px;
  padding-left: 18px;
  word-wrap: break-word;
  border-right: none;
  /* width: 350px; */
}
table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  margin-bottom: 15px;
}

table,
td,
th {
  border: 1px solid black;
}

/* tr:first-of-type:first-child,
.empHeader {
  background-color: #f88379;
} */
.heading {
  background-color: #ffe4c4 !important;
  padding-top: 12px !important;
}
table {
  margin-bottom: 10px;
}

input {
  width: 100%;
  height: 100%;
  border: 0;
  word-wrap: break-word;
}

.Red {
  background-color: red !important;
  color: black;
  font-weight: bolder;
}

.Orange,
.Amber {
  background-color: orange;
  color: black;
  font-weight: bolder;
}
.alert {
  color: red;
}
.Green {
  background-color: #32cd32 !important;
  color: black;
  font-weight: bolder;
}

.Yellow,
.IRCEP,
.IRWIP,
.Stop {
  color: black;
  font-weight: bolder;
  background-color: Yellow;
}
.green-clear {
  background-color: green;
  padding: 10px;
  font-weight: bolder;
}

.red-clear {
  background-color: red;
  padding: 10px;
  font-weight: bolder;
}

.amber-clear {
  background-color: orange;
  padding: 10px;
  font-weight: bolder;
}

.header {
  margin-bottom: 20px;
}

.footer {
  background-color: grey;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
}

.summaryRemarks input,
select {
  display: inline;
}

/* button {
    padding:2px;
    border-radius:10px;
    background-color:rgba(0,240,0,0.5);
} */

tr:last-child {
  margin-bottom: 10px;
}

.button {
  all: unset;
  /* padding:5px 10px; */
  padding: 5px 12px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: #f88379;
}
.submitBtn {
  /* border:2px solid black; */
  /* padding:3px 10px; */
  color: black;
  transition: all 0.3s;
}

/* .submitBtn:hover {
    background-color:#038f03;
    color:white;
} */
.generateBtn {
  transition: all 0.3s;
  box-shadow: 0px 5px 5px #00000030;
  cursor: pointer;
}

.generateBtn:hover,
.submitBtn:hover {
  background-color: #dd736b;
  box-shadow: 0px 3px 5px #00000030;
}
.header-divider {
  margin: 0 15px 0 15px;
  border-top: 2px solid #000000;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 20px;
}
.cellTitlewithIcon {
  background-color: rgba(211, 211, 211, 0.5);
  border-left: none;
}
.cellTitle:hover .editIcon {
  display: block;
}
.largeCelltitle {
  padding-left: 0px;
  background-color: rgba(211, 211, 211, 0.1);
}
.editIcon {
  display: none;
  float: right;
  cursor: pointer;
  border: none;
}
#colorCodeTd {
  background-color: #d3d3d3;
  padding: 3px;
  padding-left: 18px;
}
.dialog-of-modal {
  border: 1px solid #000000;
  border-radius: 5px;
}
.title-modal {
  margin-left: 15px;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: bold;
}
.content-modal {
  background-color: #e9e9ed;
}
.control-form {
  border: #000000;
}
.body-modal {
  margin: 0 15px 0 15px;
  font-family: "Manrope";
  font-size: 14px;
}

.button-submit {
  background-color: transparent;
  color: #00a6e8;
  border: 1px solid #00a6e8;
  border-radius: 5px;
  padding: 5px 30px;
  text-align: center;
  font-family: "Manrope";
}
.button-submit:hover {
  color: #ffffff;
  outline: none;
}
.button-submit-hover {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.button-submit-hover:hover {
  box-shadow: 0 0 40px 40px #00a6e8 inset;
}
.button-close {
  margin: 0;
  background-color: #00a6e8;
  color: #ffffff;
  border: 1px solid #00a6e8;
  border-radius: 5px;
  padding: 5px 30px;
  text-align: center;
  font-family: "Manrope";
}

.button-close-disable {
  margin: 0;
  /* background-color: #00a6e8; */
  color: #868686;
  border: 1px solid #00a6e8;
  border-radius: 5px;
  padding: 5px 30px;
  text-align: center;
  font-family: "Manrope";
}
.button-close:hover {
  color: #00a6e8;
  outline: 0;
}
.button-close-hover {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.button-close-hover:hover {
  box-shadow: 0 0 40px 40px #ffffff inset;
}
.button-plus {
  background-color: transparent;
  color: #00a6e8;
  border: 1px solid #00a6e8;
  border-radius: 5px;
  padding: 1px 10px;
  text-align: center;
}
.button-plus:hover {
  color: #ffffff;
  outline: none;
}
.button-plus-hover {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.button-plus-hover:hover {
  box-shadow: 0 0 40px 40px #00a6e8 inset;
}
.button-cross {
  background-color: transparent;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 1px 10px;
  text-align: center;
}
.button-cross:hover {
  color: #ffffff;
  outline: none;
}
.button-cross-hover {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.button-cross-hover:hover {
  box-shadow: 0 0 40px 40px #ff0000 inset;
}
.button-edit {
  background-color: transparent;
  color: #00a6e8;
  border: 1px solid #00a6e8;
  border-radius: 5px;
  padding: 1px 5px;
  text-align: center;
}
.button-edit:hover {
  color: #ffffff;
  outline: none;
}
.button-edit-hover {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.button-edit-hover:hover {
  box-shadow: 0 0 40px 40px #00a6e8 inset;
}

hr {
  margin: 0 30px 0 30px;
  border-top: 1px solid #000000;
}

/***********************************************************************/
@media (min-width: 2561px) {
  .header img {
    width: 7vw;
  }
}

@media (min-width: 2301px) and (max-width: 2560px) {
  .header img {
    width: 8vw;
  }
}

@media (min-width: 2001px) and (max-width: 2300px) {
  .header img {
    width: 9vw;
  }
}

@media (min-width: 1601px) and (max-width: 2000px) {
  .header img {
    width: 10vw;
  }
}

@media (min-width: 1301px) and (max-width: 1600px) {
  .header img {
    width: 11vw;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .header img {
    width: 12vw;
  }
}

@media (min-width: 1051px) and (max-width: 1200px) {
  .header img {
    width: 13vw;
  }
}

@media (min-width: 951px) and (max-width: 1050px) {
  .header img {
    width: 14vw;
  }
}

@media (min-width: 851px) and (max-width: 950px) {
  .header img {
    width: 15vw;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .header img {
    width: 17vw;
  }
}
/***********************************************************************/

/***********************************************************************/
@media (min-width: 2401px) {
  .header-address {
    width: 10%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 2001px) and (max-width: 2400px) {
  .header-address {
    width: 12%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 1751px) and (max-width: 2000px) {
  .header-address {
    width: 14%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 1551px) and (max-width: 1750px) {
  .header-address {
    width: 16%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 1440px) and (max-width: 1550px) {
  .header-address {
    width: 18%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 1201px) and (max-width: 1439px) {
  .header-address {
    width: 20%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .header-address {
    width: 24%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 951px) and (max-width: 1023px) {
  .header-address {
    width: 26%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 851px) and (max-width: 950px) {
  .header-address {
    width: 30%;
    text-align: left;
    font-weight: bolder;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .header-address {
    width: 32%;
    text-align: left;
    font-weight: bolder;
  }
}
/***********************************************************************/

.applicant-footer {
  background-color: #ffffff;
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
.apl-foot-ctn {
  margin: 15px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*
Executive summary
*/
.table-heading {
  text-align: left;
  padding-left: 5px;
  font-size: 13pt;
  font-family: Calibri, sans-serif;
}
.ES-heading {
  font-size: 14pt;
  font-family: Calibri, sans-serif;
}

.informateHeading{
  display: inline-flex;
  height: 32px;
  margin: 20px 0px;
 }
 #InfmId{
   border: solid 1px grey;
 }
 .hoverEdit{
  display: inherit;
 }
 button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.dark-bg{
  background-color: rgba(211, 211, 211, 0.5);;
}
.no-bg{
  background-color: white !important;
}